<template>
  <div v-cloak>
    <div v-if="apiLoaded">
      <transition appear appear-active-class="fade-enter-active" name="fade">
        <div class="wrap contest">
          <router-link v-if="getBackLink()" :to="getBackLink()" class="back-link">
            <v-icon class="icon-item icon-chevron-left"></v-icon>
            Назад
          </router-link>

          <h1>{{ config.title }}
            <span>{{ config.description }}</span>
            <div style="font-size: 16px">
              Даты проведения конкурса: {{ config.beginDate | dateFormat }} - {{ config.endDate | dateFormat }}
            </div>
            <div class="header-select">
              <v-autocomplete
                  v-if="checkRoles(participantRoles) || checkRoles(controllerRoles)"
                  :disabled="checkRoles(participantRoles)"
                  dense
                  label="Регион"
                  :items="regionsList"
                  item-text="name"
                  v-model="region"
                  return-object
                  @input="loadValues"
              ></v-autocomplete>
              <router-link :to="getInfoLink()">
                <v-btn outlined v-if="checkRoles(controllerRoles)">
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                       style="margin-right: 10px">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V2C20 0.9 19.1 0 18 0ZM18 16H2V2H18V16Z"
                          fill="#0033A0"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M17.41 7.42L15.99 6L12.82 9.17L11.41 7.75L10 9.16L12.82 12L17.41 7.42Z" fill="#0033A0"/>
                    <path d="M8 4H3V6H8V4Z" fill="#0033A0"/>
                    <path d="M8 8H3V10H8V8Z" fill="#0033A0"/>
                    <path d="M8 12H3V14H8V12Z" fill="#0033A0"/>
                  </svg>
                  статус загрузки регионами
                </v-btn>
              </router-link>
            </div>
          </h1>
          <div class="manual-link">
            <div v-for="attachment in attachments" :key="attachment.id">
              <a v-if="attachment.role === 'controller' && checkRoles(controllerRoles)"
                 @click="downloadAttachment(attachment)"
                 download
              >
                <v-icon class="icon-item icon-information-outline"></v-icon>
                {{ attachment.name }}
              </a>
              <a v-if="attachment.role === 'participant' && checkRoles(participantRoles)"
                 @click="downloadAttachment(attachment)"
                 download
              >
                <v-icon class="icon-item icon-information-outline"></v-icon>
                {{ attachment.name }}
              </a>
              <a v-if="attachment.role === 'all' && checkRoles(allRoles)"
                 @click="downloadAttachment(attachment)"
                 download
              >
                <v-icon class="icon-item icon-information-outline"></v-icon>
                {{ attachment.name }}
              </a>
            </div>
          </div>
          <v-tabs>
            <v-tab v-for="(nomination, nominationIndex) in config.nominations" :key="'nominationTitleIndex' + nominationIndex">
              {{nomination.name}}
            </v-tab>
            <v-tab-item v-for="(nomination, nominationIndex) in config.nominations" :key="'nominationIndex' + nominationIndex">
              <h2>
                {{nomination.name}}
              </h2>
              <table class="contest-table">
                <tbody>
                <tr v-for="(field, fieldIndex) in nomination.fields" :key="'fieldIndex' + fieldIndex">
                  <td>
                    <p>{{field.name}}</p>
                    <span>{{field.description}}</span>
                  </td>
                  <template v-if="!isEmpty(field.id) && !switchVisibleLinkEditArray[field.id]">
                    <td v-if="field.type === 'FILE'">
                      <a @click="downloadAttachment(getFile(field.id))">
                        {{ truncateStr(getFile(field.id).name, 45) }}
                      </a>
                      <span>
                        загружено {{ new Date(getValue(field.id, 'FILES')?.createdDateTime) | dateTimeFormat}} мск
                      </span>
                    </td>
                    <td v-else-if="field.type === 'LINK'">
                      <a :href="getValue(field.id, 'VALUE')?.value" target="_blank" class="external-link">
                        {{ truncateStr(getValue(field.id, 'VALUE')?.value, 45) }}
                      </a>
                      <span>сохранено {{ new Date(getValue(field.id, 'VALUE')?.createdDateTime) | dateTimeFormat}} мск</span>
                    </td>
                    <td v-else-if="field.type === 'TEXT'">
                      <div>{{ getValue(field.id, 'VALUE')?.value }}</div>
                      <span>сохранено {{ new Date(getValue(field.id, 'VALUE')?.createdDateTime) | dateTimeFormat}} мск</span>
                    </td>
                    <td>
                      <div v-if="checkRoles(participantRoles) && allowToUpdate" class="edit-menu">
                        <div class="edit-menu-icon">
                          <v-icon class="icon-item icon-dots-horizontal-circle-outline"></v-icon>
                        </div>
                        <div class="edit-menu-popup">
                          <a v-if="field.type === 'FILE'" @click="downloadAttachment(getFile(field.id))">
                            <v-icon class="icon-item icon-tray-arrow-down"></v-icon>
                            Скачать
                          </a>
                          <template v-if="field.type === 'FILE'">
                            <input type="file"
                                   :ref="field.id"
                                   style="display: none"
                                   @change="updateAttachment(field.id, $event.target.files[0])">
                            <a @click.prevent="$refs[field.id][0].click()"
                               class="upload-link"
                            >
                              <v-icon class="icon-item icon-reload"></v-icon>
                              Заменить
                            </a>
                            <a @click="updateAttachment(field.id, null)">
                              <v-icon class="icon-item icon-delete-outline"></v-icon>
                              Удалить
                            </a>
                          </template>
                          <template v-else-if="field.type === 'LINK' || field.type === 'TEXT'">
                            <a @click="switchVisibleLinkEditArray[field.id] = true; $forceUpdate()">
                              <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                              Изменить
                            </a>
                            <a @click="updateValue(field.id, null)">
                              <v-icon class="icon-item icon-delete-outline"></v-icon>
                              Удалить
                            </a>
                          </template>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <template v-if="checkRoles(participantRoles) && allowToUpdate">
                        <template v-if="field.type === 'FILE'">
                          <input type="file"
                                 :ref="'file_' + field.id"
                                 style="display: none"
                                 @change="updateAttachment(field.id, $event.target.files[0])">
                          <a @click.prevent="$refs['file_' + field.id][0].click()"
                             class="upload-link"
                          >
                            Загрузить
                          </a>
                        </template>
                        <template v-else-if="field.type === 'LINK' || field.type === 'TEXT'">
                          <v-text-field
                              dense
                              :value="getValue(field.id, 'VALUE')?.value"
                              :ref="'value_' + field.id"
                              height="45px"
                          ></v-text-field>
                        </template>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </td>
                    <td>
                      <template v-if="checkRoles(participantRoles) && allowToUpdate && (field.type === 'LINK' || field.type === 'TEXT')">
                        <v-btn @click="updateValue(field.id, $refs['value_' + field.id][0].$el.querySelector('input').value)"
                               max-width="20px"
                               title="Сохранить введённые данные"
                               outlined
                        >
                          <v-icon class="icon-item icon-content-save-outline"></v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </v-tab-item>
          </v-tabs>
        </div>
      </transition>
    </div>
    <div v-else style="height:2000px"></div>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>
import {getActiveDomainConfigs, getDomainConfigById} from "@/modules/api.configs";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";
import {
  getIndicatorsByContextAndIndicatorId,
  getRegionIndicatorsByContext,
  saveIndicators
} from "@/modules/api.indicators";
import {downloadFile, uploadFile} from "@/modules/api.files";

export default {
  name: "EventComponent",
  components: {LoaderDialog},
  props: {},
  data() {
    return {
      apiLoaded: false,
      values: [],
      domain: "event",
      allowToUpdate: true,
      switchVisibleLinkEditArray: [],
      regionsList: [],
      region: null,
      config: null,
      attachments: [],
      attachmentsIndicatorId: '09aea882-0dee-4b86-9b85-7ab5ab2aaaa5',
      participantRoles: ['CONTEST_PARTICIPANT'],
      controllerRoles: ['CONTEST_CONTROLLER', 'ROLE_ADMIN', 'ROLE_VNII'],
      allRoles: [],
      loading: false,
    };
  },
  methods: {
    async loadConfig() {
      const configId = this.$route.params.configId

      let req = configId
          ? await getDomainConfigById("event", configId)
          : await getActiveDomainConfigs("event")
      if (req.ok) {
        this.config = configId
            ? req.payload.content.sections[0]
            : req.payload[0].content.sections[0]
      } else {
        alert('Ошибка загрузки конфигурации')
      }
    },
    async loadValues() {
      this.loading = true
      let req = await getRegionIndicatorsByContext(
          this.domain,
          this.region.code,
          this.config.context
      )
      if (req.ok) {
        this.values = req.payload
      } else {
        alert('Ошибка загрузки данных')
      }
      this.loading = false
    },
    async loadAttachments() {
      let req = await getIndicatorsByContextAndIndicatorId(
          this.domain,
          this.config.context,
          this.attachmentsIndicatorId
      )
      if (req.ok) {
        const value = req.payload
        if (value && value.length && value[0].value) {
          this.attachments = JSON.parse(value[0].value)
        }
      } else {
        alert('Ошибка загрузки данных о статических файлах')
      }
    },

    getValue(id, type) {
      return this.values.find(e => e.indicatorId === id && e.type === type)
    },
    getFile(id) {
      const value = this.getValue(id, 'FILES')?.value
      return value ? JSON.parse(value)[0] : null
    },
    isEmpty(id) {
      const value = this.getValue(id, 'VALUE')?.value
      const files = this.getValue(id, 'FILES')?.value

      return !value && (!files || files === '[]');
    },

    async updateValue(indicatorId, value) {
      this.loading = true

      const savedValues = [{
        indicatorId: indicatorId,
        regionCode: this.region.code,
        context: this.config.context,
        value: value,
        type: 'VALUE'
      }]
      await saveIndicators(savedValues)
      await this.loadValues()

      this.switchVisibleLinkEditArray[indicatorId] = false
      this.$forceUpdate()
      this.loading = false
    },

    async updateAttachment(indicatorId, file) {
      this.loading = true
      let attachedFile = []

      if (file) {
        const uploadedFile = await uploadFile(file)
        attachedFile = [{
          id: uploadedFile.id,
          name: uploadedFile.name
        }]
      }

      const savedValues = [{
        indicatorId: indicatorId,
        regionCode: this.region.code,
        context: this.config.context,
        value: JSON.stringify(attachedFile),
        type: 'FILES'
      }]
      await saveIndicators(savedValues)
      await this.loadValues()

      this.$forceUpdate()
      this.loading = false
    },

    async downloadAttachment(attachment) {
      this.loading = true
      await downloadFile(attachment.id, attachment.name)
      this.loading = false
    },

    getBackLink() {
      const configId = this.$route.params.configId
      if (configId) {
        return "/config/event"
      }
    },

    getInfoLink() {
      const configId = this.$route.params.configId
      if (configId) {
        return "/eventRegions/" + configId
      } else {
        return "/eventRegions"
      }
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 10, str.length)
      }
      return str
    },

    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },

    checkAllowToUpdate() {
      const configId = this.$route.params.configId

      let actualDate = this.$serverDateTime,
          beginDate = new Date(this.config.beginDate),
          endDate = new Date(this.config.endDate);
      if (!(actualDate >= beginDate && actualDate <= endDate)) {
        this.allowToUpdate = false
      }
      if (configId) {
        this.allowToUpdate = false
      }
    }
  },

  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      const mdate = new Date(date)
      const options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    },

    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    this.allRoles = this.participantRoles.concat(this.controllerRoles);
    await this.loadConfig();
    this.checkAllowToUpdate();
    this.regionsList = this.$cznDepartmentsByRegionList.filter(r => this.config.regions.includes(r.code))
    this.regionsList.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (this.checkRoles(this.participantRoles)) {
      this.region = this.$user.region
    }
    else if (this.checkRoles(this.controllerRoles)) {
      this.region = this.regionsList[0]
    }

    if (this.checkRoles(this.controllerRoles) || this.regionsList.map((regionName) => regionName.name).includes(this.region.name)) {
      for (let i = 0; i < this.config.nominations.length; i++) {
        this.switchVisibleLinkEditArray.push([]);
      }
      await this.loadValues()
      await this.loadAttachments()
      this.apiLoaded = true;
    } else {
      alert("Ваш регион не участвует в данном конкурсе")
    }
  },
}
</script>

<style lang="scss">
@import "../../styles/main.scss";

.contest .v-tabs-bar {
  margin-bottom: 60px !important;
}
</style>

<style lang="scss" scoped>
.header-select {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  .v-select {
    flex-grow: 0;
    width: 520px;
    font-size: 13px;
  }

  .v-btn {
    margin-left: auto;
    height: 56px;
  }

  a {
    text-decoration: none;
  }
}

.manual-link {
  margin-bottom: 40px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      margin-right: 10px;
    }
  }
}

.v-icon {
  color: #0033a0;
}

.v-input {
  font-size: 13px;
}

.contest-table {
  width: 100%;
  border-collapse: collapse;

  td {
    border-bottom: 1px solid #E8E8E9;
    padding: 10px 0;
    font-size: 12px;

    a:not(.upload-link) {
      font-size: 13px;
      text-decoration: none;
    }

    a.external-link {
      text-decoration: underline;
    }

    div {
      font-size: 13px;
      color: #1C1C1F;
      font-style: normal;
      font-weight: 500;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: #1C1C1F;
      margin: 0;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #777779;
      display: block;
    }

    .edit-menu {
      height: auto;
      opacity: 1 !important;
      float: right;

      .edit-menu-popup {
        z-index: 999 !important;
        background: #fff !important;
        width: 158px;

        .v-icon {
          margin-right: 10px;
        }

        a:hover {
          background-color: #ebeff8; //rgba(0, 51, 160, 0.1);
        }
      }
    }

    .v-btn {
      min-width: 20px;
      margin-left: 10px;
    }

    &:nth-child(2) {
      text-align: right;
    }

    &:last-child {
      text-align: right;
      width: 30px;
    }
  }
}

</style>
